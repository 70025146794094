import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { getPropertiesByAddress, setFilterHome } from "../redux/propertiesDucks";
import $ from 'jquery';
import { Markup } from "interweave";
import { useLocation } from "@reach/router";
import { Modal } from "react-bootstrap";


const Autocomplete = ({updateRoute,fetching_properties,searchHome,dispatch, properties_home }) => {
  const [searchtext, setSearchtext] = useState("");
  const [suggest, setSuggest] = useState([]);
  const [resfound, setResfound] = useState(true);
  const [listTextGenerated,setListTextGenerated] = useState([])
  const location = useLocation()
  const [show,setShow] = useState(false);

  const miInputRef = useRef(null);

  const establecerFoco = () => {
    miInputRef.current.focus();
  };

  var controladorTiempo = "";

  const handleChange = (e) => {
    let searchval = e.target.value;
    setSearchtext(searchval);
  };

  const suggestedText = (value) => {
    setSearchtext(value.split(" | ")[0]);
    setSuggest([]);
  };


  useEffect(() => {
    setListTextGenerated(groupPropertiesFunction(properties_home.objects).texts_results)
    let suggestion = [];
    suggestion = listTextGenerated.sort();
    if(searchtext.length > 2){
      setResfound(suggestion.length !== 0 ? true : false);
      setSuggest(suggestion);
    }
  },[properties_home])

  useEffect(() => {
    if(miInputRef?.current){
      miInputRef.current.focus();
    }
  },[show])

  useEffect(() => {
    let suggestion = [];
    suggestion = listTextGenerated.sort();
    if(searchtext.length > 2){
      setResfound(suggestion.length !== 0 ? true : false);
      setSuggest(suggestion);
    }
  },[listTextGenerated])

  const groupPropertiesFunction = () => {
    let results = []
    let texts_results = []
    results.push({"fake_address":searchtext,"count":properties_home?.length})
    for(let i = 0; i < properties_home?.length; i++) {
      if(results.find(element => element.fake_address === properties_home[i].fake_address)){
        let same = results.find(element => element.fake_address === properties_home[i].fake_address)
        same.count+=1
        results = [...results.filter(result => result.fake_address !== properties_home[i].fake_address), same]
      }
      else{
        results.push({"fake_address":properties_home[i].fake_address,"count":1})
      }
    }
    results = results.sort(function(a,b) {
      return b.count - a.count;
    });

    for(let i = 0; i < results.length; i++){
      // if(searchtext.length > 2){
        console.log(i)
        if(i < 4){
          texts_results.push(results[i].fake_address + " | " + results[i].count + " resultados")
        }
      // }
    }
    return {results,texts_results}
  }

  useEffect(() => {
    $("#your-field").on("keyup", function() {
      clearTimeout(controladorTiempo);
      controladorTiempo = setTimeout(codigoAJAX, 250);
    });
    function codigoAJAX() {
      // llamada AJAX
      dispatch(getPropertiesByAddress(searchHome.address))

    }
    dispatch(setFilterHome({...searchHome,address:searchtext}))
    updateRoute({current:{value:searchtext,name:'address'}})
  },[searchtext])

  function focusInputText() {
    $("#your-field").focus();
    // inputAddress.focus();
  }

  useEffect(() => {
    dispatch(setFilterHome({...searchHome,address:""}))
  },[location])

  function toMarkup(text,value) {
    if(value === ''){
        return text.toLowerCase()
    }
    text = text.toLowerCase().replaceAll(value.toLowerCase(), "<b>" + value.toLowerCase() +"</b>");
    return text;
}

  const sortSuggest = (suggest) => {
    let order_suggest = []
    for(const result of groupPropertiesFunction().results){
      const finder = suggest.find(element => element.toLowerCase().split(" | ")[0] == result.fake_address.toLowerCase());
      if(finder){
        order_suggest.push(finder)
      }
    }
    return order_suggest
  }

  const getSuggestions = () => {
    if (suggest.length === 0 && searchtext !== "" && !resfound) {

    }

    return (
      <ul className={searchtext.length > 2 ? 'd-block' : 'd-none'}>
        {fetching_properties
          ? <div className="d-flex align-items-center w-100 hover-color">
              <li>
                Cargando resultados..
              </li>
            </div>
          : sortSuggest(suggest).map((item, index) => {
            return (
              <div key={index} className="d-flex align-items-center w-100 hover-color">
                <li onClick={() => suggestedText(item) + setShow(false)}>
                  {}
                  <Markup content={toMarkup(item,searchtext)} />
                </li>
                {index !== suggest.length - 1 && <hr />}
              </div>
            );
          })
        }
      </ul>
    );
  };

  return (
    <div className="searchcontainer">
      <input
        type="text"
        placeholder="Buscar por calle o avenida"
        className="search"
        id="textAddress"
        value={searchtext}
        onClick={() => setShow(!show) + establecerFoco}
        // onChange={handleChange}
      />
      <Modal className="modal-pro address"  show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" contentClassName="modal-mobile" centered>
        <Modal.Header closeButton>
            <Modal.Title>Buscar por calle o avenida</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            placeholder="Ingresar calle o avenida"
            className="search search-mobile w-100"
            id="your-field"
            ref={miInputRef}
            value={searchtext}
            onChange={handleChange}
          />
          {getSuggestions()}
        </Modal.Body>
        <Modal.Footer>
                      <div className="control-float float-home position-sticky w-100 d-flex justify-content-between not-closer">
                        <buttton onClick={() => dispatch(setFilterHome({...searchHome,address:""})) + setSearchtext("")} className="btn btn-white not-closer">
                            Limpiar
                        </buttton>
                        {/* <buttton onClick={() => setShow(false)} className="btn btn-red border not-closer">
                            Aplicar
                        </buttton> */}
                    </div>
            {/* <button onClick={() => setShow(false)} className="btn w-100 btn-red">Aplicar</button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default connect(state => ({
  totalListFilters: state.properties.totalListFilters,
  searchHome:state.properties.searchHome,
  fetching_properties:state.properties.fetching_properties,
  properties_home:state.properties.properties_home
}),null)(Autocomplete);

import React, {useRef, useEffect} from 'react'
import Card from '../property-card'
import { graphql, useStaticQuery } from 'gatsby';
import OwlCarousel from 'react-owl-carousel3';

//Redux
import { connect } from 'react-redux'

//Dispatch
import {getLatestPropertiesAction} from '../../redux/propertiesDucks'

import { useLocation } from "@reach/router";

const options = {
    loop:true,
    smartSpeed:1000,
    stagePadding:10,
    // center:true,
    margin:10,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        991: {
            items: 1,
        },
        992: {
            items: 3,

        }
    },
}

const Properties = ({properties,api_key,dispatch}) => {

    const slider = useRef()
    const { realEstate } = useStaticQuery(graphql`
        query {
            realEstate {
                sections {
                    home {
                        properties{
                            title
                        }
                    }
                }
            } 
        }`)

    const location = useLocation()
    const isPropiedadPage = location.pathname.includes('/propiedad')

    useEffect(() => {
        if(api_key){
            dispatch(getLatestPropertiesAction())
        }
    }, [api_key])

    const mockRealEstate = {
        sections: {
            home: {
                properties: {
                    title: isPropiedadPage ? "Propiedades similares." : "Últimos ingresos."
                }
            }
        }
    }

    return properties?.data?.objects?.length > 0 ? (
        <section id="featured-props" className={`${isPropiedadPage ? 'similar-prop' : 'props-wrapper'}`}>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <h2 class="section-title pb-lg-5 pb-3">
                            {realEstate.sections.home.properties.title}
                        </h2>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 px-0">
                        <div className="props d-flex align-items-center justify-content-center my-4">
                            <OwlCarousel ref={slider}
                                {...options}
                                className="overflow-hidden">
                                {properties.data.objects.map((prop,index) => (
                                    <Card key={index} basic={true} property={prop}/>
                                ))}
                            </OwlCarousel>
                            <div className="arrows">
                                <div className="arrow" onClick={() => slider.current.prev()}> {'‹'} </div>
                                <div className="arrow" onClick={() => slider.current.next()}> {'›'} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ):''
}

export default connect(state => ({
    properties: state.properties.featured,
    api_key:state.settings.keys.tokko
}),null)(Properties);
import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
const ClientSideOnlyLazy = React.lazy(() =>
  import("../components/Home/properties")
)


//Components
import Main from '../components/Home/main'
// import Developments from '../components/Home/developments'
import Properties from '../components/Home/properties'
import About from '../components/Home/about'
import Novelties from '../components/Home/novelties'
// import Investors from '../components/investors'
import MainDevelopment from "../components/Home/maindevelopment"
import WpSection from "../components/Home/wpsection"
import VideoSection from "../components/videosection"

const IndexPage = () => {
  const isSSR = typeof window === "undefined"
      
  return(
  <Layout>
    <Seo title="Venta y Alquiler de Propiedades" />
    <Main />
    <VideoSection sectionId="home" videoSrc="//www.youtube.com/embed/XZK0OA2tmIM?playlist=XZK0OA2tmIM&amp;version=3&amp;autoplay=0&amp;rel=0&amp;controls=1&amp;showinfo=0&amp;mute=0&amp;loop=1&amp;iv_load_policy=3&amp;modestbranding=1&amp;vq=hd720&amp;"/>
    {/* <Developments /> */}
    {!isSSR && (
      <React.Suspense fallback={<div />}>
        <ClientSideOnlyLazy />
      </React.Suspense>
    )}
    <MainDevelopment/>
    <WpSection/>
    <About />
    {/* <Investors /> */}
    <Novelties />
  </Layout>
)}

export default IndexPage

import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from 'gatsby';
import aboutImg from "../../images/about-services.jpg"
import IconRightSvg from "../../images/svg/icon-right.svg"

const About = () => {
    const { realEstate } = useStaticQuery(graphql`
            query {
                realEstate {
                    sections {
                        home {
                          about {
                            title
                            paragraph
                            image
                            buttons {
                              link
                              value
                            }
                            items {
                              paragraph
                              value
                            }
                          }
                        }
                    }
                }
    }`)

    const mockRealEstate = {
        sections: {
            home: {
                about: {
                    title: ["Más de 30 años de", "Marketing Inmobiliario"],
                    text: "Buscando la satisfacción total de nuestros clientes y cumpliendo los sueños de miles de familias.",
                    img: {
                        link: aboutImg,
                        alt: "About us"
                    },
                    btn: {
                        link: "/nosotros",
                        text: "Conocé más"
                    }
                }
            }
        }
    }

    return realEstate.sections && (
        <section id="marketing" class="about-us">
            <div class="container-fluid px-0">
                <div class="row no-gutters">
                    <div class="col-lg-6 d-flex align-items-center justify-content-left px-4 px-lg-0">
                        <div class="half-container">
                            <h5>{realEstate.sections.home.about.title}</h5>
                            <h2>
                                <span> {mockRealEstate.sections.home.about.title[0]} </span> <br class="d-block"/>
                                {mockRealEstate.sections.home.about.title[1]}
                            </h2>
                            <p>{mockRealEstate.sections.home.about.text}</p>
                            <Link to={mockRealEstate.sections.home.about.btn.link} class="btn btn-green">
                                {mockRealEstate.sections.home.about.btn.text} <IconRightSvg class="icon"/></Link>
                        </div>

                    </div>
                    <div class="col-lg-6 px-0 d-none d-lg-block">
                        <img src={mockRealEstate.sections.home.about.img.link} 
                            alt={mockRealEstate.sections.home.about.img.alt}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
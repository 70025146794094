import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'gatsby'
import OwlCarousel from 'react-owl-carousel3'
import { connect } from 'react-redux'
import { getDevelopmentsAction} from '../../redux/developmentsDucks'
import { getQuality, scrollToElement } from '../../helpers/helper.rendering';
import { getLastFeatured, getFilterReverseDevs, getFakeAddress, getLocation } from '../../helpers/helper.developments'
import IconRightSvg from "../../images/svg/icon-right.svg"
import { useLocation } from "@reach/router";

const options = {
    loop: true,
    smartSpeed: 1000,
    stagePadding: 0,
    margin: 0,
    responsive: {
        0: {
            items: 1,
        }
    },
}

const MainDevelopment = ({properties, developments, api_key, dispatch}) => {

    const slider = useRef()
    const location = useLocation()
    const [featured,setFeatured] = useState({})


    useEffect(() => {
        if(api_key){
            dispatch(getDevelopmentsAction())
        }
    }, [api_key])

    useEffect(() => {
        setFeatured(getLastFeatured(getFilterReverseDevs(developments?.data?.objects)))
    },[developments])

    const mockRealEstate = {
        sections: {
            home: {
                mainDev: {
                    title: "NUESTROS EMPRENDIMIENTOS",
                    subtitle: "SHULBIUS DREAM",
                    location: "Hipólito Yrigoyen al 2400, Florida, Vicente López",
                    text: "Diseño de vanguardia con materiales de primera calidad.",
                    btn: {
                        link: "/emprendimiento/hipolito-yrigoyen-al-2400-florida/22660",
                        text: "Conocé más"
                    },
                    link: {
                        to: "/emprendimientos",
                        text: "Ver Todos"
                    }
                }
            }
        }
    }

    const mockRealEstateEmprendimiento = {
        sections: {
            emprendimientos: {
                mainDev: {
                    title: "COLECCIÓN SHULBIUS BY BELLAGAMBA",
                    subtitle: "Espacios pensados para disfrutar.",
                    img: {
                        src: "https://bellagamba.com/svg/shulbius.svg",
                        alt: "Shulbius by Bellagamba"
                    },
                    text: "Tu hogar es el único lugar en el mundo donde las cosas son como vos querés que sean. Y eso es Shulbius, la combinación perfecta entre diseño, lugar, ambiente, comodidades y vos. Una experiencia de vida donde se cumplen todas tus expectativas.",
                    link: {
                        to: "shulbius",
                        text: "Conocé más"
                    }
                }
            }
        }
    }

    return !featured?.id ? '' : (
        <section id="shulbius" class="about-us">
            <div class="container-fluid px-0">
                <div class="row no-gutters">
                    <div class="col-lg-4 d-flex align-items-center justify-content-left">
                        <div class="half-container px-4">
                            <h5>NUESTROS EMPRENDIMIENTOS</h5>
                            <h3>{featured.name}</h3>
                            <h4>{getFakeAddress(featured)}</h4>
                            <p>{featured.publication_title}</p>
                            <div class="d-flex align-items-center justify-content-between content-buttons">
                                <a target={"_blank"} href={`/emprendimientos/${featured.id}`} class="btn btn-orange">
                                    {mockRealEstate.sections.home.mainDev.btn.text}
                                    <IconRightSvg class="icon"/>
                                </a>
                                <Link to={"/emprendimientos/"} class="underline">
                                    {mockRealEstate.sections.home.mainDev.link.text}
                                </Link>
                            </div>       
                        </div>
                    </div>
                    <div class="col-lg-8 px-0 d-flex flex-column main-dev-gallery" id="main-dev-gallery">
                        <OwlCarousel ref={slider}
                            {...options}
                            className="overflow-hidden">
                            {featured.photos.map((photo, index) => (
                                <div key={index}>
                                    <img className="object-fit position-center" src={getQuality(photo)} 
                                        alt={featured.name} />
                                </div>
                            ))}
                        </OwlCarousel>
                        <div className="arrows align-self-center">
                            <div className="arrow" onClick={() => slider.current.prev()}> {'‹'} </div>
                            <div className="arrow" onClick={() => slider.current.next()}> {'›'} </div>
                        </div>
                    </div>
                </div>
                { location.pathname.includes('/emprendimientos') ? 
                    <button class="goto" 
                        onClick={() => scrollToElement("section.development-about")}>
                        <span>
                            <IconRightSvg class="icon"/>
                        </span>
                    </button> : '' }
            </div>
        </section>
    )
}

export default connect(state => ({
    properties: state.properties.featured,
    developments: state.developments.developments,
    api_key:state.settings.keys.tokko
}),null)(MainDevelopment);
import React from "react"
import { Link } from "gatsby"
import wpLogo from "../../images/wp-section.png"
import IconRightSvg from "../../images/svg/icon-right.svg"

const WpSection = () => {

    const mockRealEstate = {
        sections: {
            home: {
                whatsapp: {
                    title1: ["Whatsappeá,", "tasá y vendé tu propiedad."],
                    title2: "Whatsapp. 11-3479-8081",
                    img: {
                        link: wpLogo,
                        alt: "Whatsapp logo"
                    },
                    text: "Contamos con 30 años de experiencia en el mercado inmobiliario. Nuestra fórmula contempla la interacción de múltiples variables para llegar al valor real de una propiedad. Así, evaluamos hasta los más mínimos detalles para fundamentar con datos sólidos cada producto en particular.",
                    btn: {
                        link: "/tasaciones",
                        text: "TASÁ TU PROPIEDAD"
                    }
                }
            }
        }
    }

    return (
        <section className="wp-section py-5">
            <div className="container-fluid py-5">
                <div className="row">
                    <div className="col-lg-4">
                        <h3>{mockRealEstate.sections.home.whatsapp.title1[0]}<br/> 
                            {mockRealEstate.sections.home.whatsapp.title1[1]}</h3>
                        <h3 className="mt-lg-5 mt-3 green">{mockRealEstate.sections.home.whatsapp.title2}</h3>
                    </div>
                    <div className="col-lg-4 text-center justify-content-center align-items-center d-flex my-5 my-lg-0 ">
                        <img src={mockRealEstate.sections.home.whatsapp.img.link} 
                            alt={mockRealEstate.sections.home.whatsapp.img.alt}/>
                    </div>
                    <div className="col-lg-4">
                        <div>
                            <p>{mockRealEstate.sections.home.whatsapp.text}</p>
                            <Link to={mockRealEstate.sections.home.whatsapp.btn.link} class="btn btn-green">
                                {mockRealEstate.sections.home.whatsapp.btn.text} <IconRightSvg class="icon"/></Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WpSection
